
.bd {

  color: #555;
}

@media (min-width: 48em) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
	.d-flex {
		display: none ;
	}
	.justify-content-end {
		display: none;
	}
	.img-logo {
		justify-content: center !important;

	}
}


/*edia (min-width: 1500px)
	.justify-content-center {
		display: none ;
	}
	
}*/
	


h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #333;
}


body > .container {
  padding: 10px 15px 10px;
}

.container-custom {
  padding: 0px 0px 49px !important;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

.clear {
	clear: both;
	margin-bottom: 20px;
}

label {
    margin-bottom: .2rem;
	font-weight: 500;
}
	
.login-bar {
  margin-bottom: 3rem;
  background-color: #D6F1FC;
 /* -webkit-box-shadow: inset 0 -.1rem .25rem rgba(0,0,0,.1);
  box-shadow: inset 0 -.1rem .25rem rgba(0,0,0,.1);*/
}

.card-1 {
	background-color: #DEE4E5 !important;
	border-radius: calc(1.5rem - 8px) calc(1.5rem - 8px) calc(1.5rem - 8px) calc(1.5rem - 8px) !important;
}

.card-2 {
	background-color: #133656;
	color:#FFFFFF;
	text-align:center;
	border-radius: calc(1.5rem - 8px) calc(1.5rem - 8px) 0 0 !important;
	padding: 1.00rem 7.50rem;
	font-size: 20px;
}

.card-custom {
	padding:25px 20px 0px 20px  !important;
}

.forgot-custom {
	margin-bottom: 0.2rem !important;
}

.input-radius-left {
	border-top-left-radius: .50rem !important;
	border-bottom-left-radius: .50rem !important;
	background-color: #ffffff;
	}

.input-radius-right {
	border-top-right-radius: .50rem !important;
	border-bottom-right-radius: .50rem !important;
	}	
	

.in-ic {
	background-color:#FFF;
}

.form-custom-1 {
	border-top: 1px solid #ced4da;
	border-bottom: 1px solid #ced4da;
	border-left: none;
	border-right:1px solid #ced4da;
	height: calc(2.70rem + 2px);
}

.btn-custom {
	background-color:#3FA08D;
	border-color:#3FA08D;
	border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) calc(0.5rem - 1px) calc(0.5rem - 1px) !important;
	color:#fff;
}

.btn-size {
	padding: 6px 40px;
}

.btn-primary:hover {
	background-color:#E5C151;
	border-color:#E5C151;
	color:#133656;
	
}

.forgot-custom {
	color:#000;
	font-size:12px;
}

.forgot-link {
	color: #55595C;
}

.info-custom {
	color:#133656;
	font-size:13px;
}

.banner-start {
	padding: 60px 0px 0px 0px;
}


.banner-customr{
	text-align:center;
}

.emblem-text {
	font-weight: bold;
	font-size:12px;
}

/* Nav links */
.nav-link {
  position: relative;
  padding: 1rem;
  font-weight: 500;
  color: #cdddeb;
}

.logo-spacing {
	padding: 5px 5px;
	
	
}

.row-own {
	margin-right: 0px !important;
margin-left: 0px !important;
}

.nav-link:hover,
.nav-link:focus {
  color: #000;
  background-color: transparent;
}

/* Active state gets a caret at the bottom */
.nav-link.active {
  color: #fff;
}
.nav-link.active:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -.3rem;
  vertical-align: middle;
  content: "";
  border-right: .3rem solid transparent;
  border-bottom: .3rem solid;
  border-left: .3rem solid transparent;
}


/* Footer */
.footer_inner {
 
 bottom: 0;
  width: 100%;
  
  line-height: 15px;
  background-color: #E9E9E9;
  border-top: 4px solid #3EA093;
	padding: 10px;
}

.footer-text-inner {
	font-size:12px;
	padding-top: 6px;
}

.footer-flex {
 flex-direction: column;
 padding-top:10px;
}

@media (min-width: 360px)  and (max-width: 414px){
    .footer {
        
        /* height: 128px; */
	}
	.container-custom {
		padding:0px 0px 64px !important; 
	}
}

@media (max-width:776px) {
	   .container-custom {
    padding: 0px 0px 70px !important;
}
}

@media (max-width:320px) {
	   .container-custom {
    padding: 0px 0px 95px !important;
}
}

