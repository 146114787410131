@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700,300");

html {
  position: relative;
  min-height: 100%; 
  }

body {
  font-size: 0.9rem;
 
  /*padding-bottom: 50px;*/

}

a {
    color: #0ca4ca;
    text-decoration: none; }

.small {
	font-size: 0.7rem;
}

.noradius {
	border-radius: 0;
}

.noborder {
	border: 0 !important;
}

.radius-circle {
	border-radius: 50px; }
	
label {
  font-weight: 500; }

.label {
  display: inline-block;
  padding: 0.2em 0.6em;
  font-size: 75%;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }

.label:empty {
  display: none; }

img {
    vertical-align: middle; 
	border: 0; }

.bg-primary {
  background-color: #0f9df7 !important; }

.bg-success {
  background-color: #1bb99a !important; }

.bg-info {
  background-color: #3db9dc !important; }

.bg-warning {
  background-color: #f1b53d !important; }

.bg-danger {
  background-color: #ff5d48 !important; }

.bg-purple {
  background-color: #9261c6 !important; }

.bg-pink {
  background-color: #ff7aa3 !important; }

.bg-dark {
  background-color: #2b3d51 !important; }
  
.bg-default {
  background-color: #64b0f2 !important; }

  
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-custom {
  color: #64b0f2; }

.text-primary {
  color: #0f9df7 !important; }

.text-success {
  color: #1bb99a !important; }

.text-info {
  color: #3db9dc !important; }

.text-warning {
  color: #f1b53d !important; }

.text-danger {
  color: #ff5d48 !important; }

.text-purple {
  color: #9261c6; }

.text-pink {
  color: #ff7aa3; }

.text-dark {
  color: #2b3d51; }

.text-white {
  color: #ffffff; }

.text-muted {
  color: #818a91; }

.dropdown-lg {
  width: 280px; }

.dropdown-menu {
	box-shadow: none;
	border: 1px solid rgba(43, 61, 81, 0.05);
	margin: -1px;
	padding: 0;
	border-radius: 0;
}


::-webkit-scrollbar
{
  width: 12px;  /* for vertical scrollbars */
  height: 12px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track
{
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb
{
  background: rgba(0, 0, 0, 0.6);
}
  
.breadcrumb-holder {
  background-color: #ffffff;
  margin: 0px 0px 20px 0px;
  padding: 13px 25px 13px 25px;
  box-shadow: none; }

.breadcrumb-holder .main-title {
    margin-bottom: 0;
    font-size: 1.4rem; }
	
.breadcrumb-holder .breadcrumb {
    padding: 0;
    background-color: transparent !important; }

.card-body-2 {
  background-color: #EFEFEF;
padding: 4px 16px 4px 16px;
margin-top: 0px;
}

.card-box {
  padding: 20px;
  background-clip: padding-box;
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid rgba(183,183,183,.125);  }

.card-box i {
	font-size: 80px; 
	margin-top: 20px;}
	
.card-header {
	color: #FFFFFF;    
    background-color: #14A39F;
    border-bottom: 1px solid rgba(111,111,111,.13);
}

.card-header h3 {
    font-weight: 500;
    line-height: 1.2;
	font-size: 1.2rem;
	margin-bottom: 0;
}
  
.header-title {
  font-size: 1rem;
  text-transform: uppercase; }

.page-header {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 1.15rem;
  padding-bottom: 10px;
  border-bottom: 1px solid #eceeef; }

.button-list {
  margin-bottom: -5px;
  margin-left: -7px; }
  .button-list button, .button-list a {
    margin: 5px 7px; }

canvas {
  max-width: 100%; }

svg {
  max-width: 100%; }

a {
  outline: none !important;
  text-decoration: none !important; }

.row {
  /*margin-right: -10px;
  margin-left: -10px;*/ }

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9 {
  padding-right: 10px;
  padding-left: 10px; }

.nav-pills .nav-item.show .nav-link, .nav-pills .nav-link.active {
  background-color: #0f9df7; }

.dropdown-item {
  font-size: 0.9rem;
  white-space: inherit;
}


/* IE9, IE10, IE11 */
@media screen and (min-width: 0\0) {
	.form-control {
		padding: 0.55rem 0.75rem !important;
	}

	.input-group-addon {
		line-height: inherit !important;
		font-size: 1rem !important;
	}

	.carousel-control span {
		right: 20px;
	}
}

.headerbar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999; }

.headerbar .headerbar-left {
    background: #D6F1FC;
    float: left;
    text-align: center;
    height: 70px;
    position: relative;
    width: 250px;
    z-index: 1; }
   
.headerbar .headerbar-left .logo img {
   /* max-width: 100px;
    max-height: 30px;*/
    width: auto;
    height: auto;
}
	  
.navbar-custom {
  background-color: #D6F1FC ;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0 10px;
  margin-left: 250px;
  min-height: 70px;}
  
.navbar-custom .nav-link {
    padding: 0 0px;
    line-height: 50px;
    color: #025E79;
	font-size: 20px; }

.navbar-custom .dropdown-toggle:after {
    content: initial; }

.navbar-custom .menu-left {
    overflow: hidden; }

.logo {
  color: #e3ebf3 !important;
  font-size: 20px;
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 1px; 
  line-height: 48px; }
.headerbar .mobile {
	   display: none;
  }
@media (max-width: 600px) {
    .headerbar .mobile {
      display: block;
    }
    .headerbar .logo {
      display: none;
    }
  }

  
.logo span span {
    color: #fff; }

.main-sidebar {
  top: 70px;
  width: 250px;
  z-index: 10;
  background: #133656;
  bottom: 70px;
  margin-bottom: -70px;
  margin-top: 0;
  padding-bottom: 70px;
  position: absolute; }

.content-page {
  margin-left: 250px;
  overflow: hidden; }
  
.content-page .content {
    padding: 0 5px;
    margin: 80px 18px 80px 18px; }

.main-sidebar.left {
  position: absolute; }

body.adminbody .main-sidebar.left {
  bottom: 0px;
  margin-bottom: 0px;
  margin-top: 0;
  padding-bottom: 0px; }

.button-menu-mobile {
  background: transparent;
  border: none;
  color: #006377;
  font-size: 28px;
  line-height: 50px;
  padding: 5px 5px;
  margin-right: 10px;
	position: relative;
	cursor: pointer;
	display: inline-block;
	overflow: hidden; 
	vertical-align: middle;
	z-index: 1;  }
  .button-menu-mobile:hover {
    color: #14A39F; }

.sidebar-inner {
  height: 100%; }

#sidebar-menu, #sidebar-menu ul, #sidebar-menu li, #sidebar-menu a {
  border: 0;
  font-weight: normal;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none; }

#sidebar-menu {
  padding-bottom: 30px;
  width: 100%; }
  #sidebar-menu a {
    line-height: 1.3; }
  #sidebar-menu ul li .menu-arrow {
    -webkit-transition: -webkit-transform .15s;
    -o-transition: -o-transform .15s;
    transition: transform .15s;
    position: absolute;
    right: 20px;
    top: 15px;
    display: inline-block;
    font-family: 'FontAwesome';
    text-rendering: auto;
    line-height: 18px;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }
    #sidebar-menu ul li .menu-arrow:before {
      content: "\f105"; }
  #sidebar-menu ul li a.subdrop .menu-arrow {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg); }
  #sidebar-menu ul ul {
    display: none; }
    #sidebar-menu ul ul li {
      border-top: 0; }
    #sidebar-menu ul ul li.active a {
      color: #f3f3f3; }
    #sidebar-menu ul ul a {
      color: #b5b5b5;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      display: block;
      padding: 10px 20px 10px 60px; }
      #sidebar-menu ul ul a:hover {
        color: #efefef; }
      #sidebar-menu ul ul a i {
        margin-right: 5px; }
    #sidebar-menu ul ul ul a {
      padding-left: 80px; }
  #sidebar-menu .label {
    margin-top: 2px; }
  #sidebar-menu .subdrop {
    color: #fff; 
	background-color: #414d58; 
	border-left: 2px solid #608ab3; }

#sidebar-menu .subdrop:hover {
	color: #133656;
}
	
  #sidebar-menu .submenu ul .submenu .menu-arrow {
    top: 10px !important; }

#sidebar-menu > ul > li > a {
  color: #FFFFFF;
  display: block;
  padding: 12px 12px 15px 12px;
  font-weight: 400;
  font-size: 0.90rem;
  border-left: 2px solid transparent;  }

#sidebar-menu > ul > li > a:hover {
    color: #133656;
	background-color: #D6F1FC;
    text-decoration: none; 
	border-left: 2px solid #608ab3; }

#sidebar-menu > ul > li > a > span {
  vertical-align: middle; }

#sidebar-menu ul li a i {
  display: inline-block;
  font-size: 18px;
  line-height: 17px;
  margin-left: 3px;
  margin-right: 15px;
  text-align: center;
  vertical-align: middle;
  width: 20px; }
#sidebar-menu ul li a .drop-arrow {
  float: right; }
  #sidebar-menu ul li a .drop-arrow i {
    margin-right: 0; }

#sidebar-menu > ul > li > a.active {
  color: #fff !important;
  background-color: #E5C151; }

.menu-title {
  padding: 12px 20px !important;
  letter-spacing: .035em;
  pointer-events: none;
  cursor: default;
  font-size: 13px; }

.footer {
	
    bottom: 0;
    color: #818a91;
    text-align: left !important;
    padding: 10px;
    position: absolute;
    right: 0;
    left: 250px;
    }

#main.enlarged .menu-title {
  display: none; }
#main.enlarged .menu-arrow {
  display: none !important; }
#main.enlarged .footer {
  left: 70px; }
#main.enlarged #sidebar-menu ul ul {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 10px 0 rgba(0, 0, 0, 0.08); }
#main.enlarged .left.main-sidebar {
  width: 70px;
  z-index: 5; }
  #main.enlarged .left.main-sidebar #sidebar-menu > ul > li > a {
    padding-left: 10px; }
    #main.enlarged .left.main-sidebar #sidebar-menu > ul > li > a:hover {
      background-color: #64b0f2; }
  #main.enlarged .left.main-sidebar span.pull-right {
    display: none !important; }
  #main.enlarged .left.main-sidebar #sidebar-menu ul > li {
    position: relative;
    white-space: nowrap; }
    #main.enlarged .left.main-sidebar #sidebar-menu ul > li:hover > a {
      background-color: #64b0f2;
      position: relative;
      width: 260px; }
    #main.enlarged .left.main-sidebar #sidebar-menu ul > li:hover > ul {
      display: block;
      left: 70px;
      position: absolute;
      width: 190px;
      padding: 5px 0;
      background: #ffffff; }
      #main.enlarged .left.main-sidebar #sidebar-menu ul > li:hover > ul a {
        background: #ffffff;
        border: none;
        box-shadow: none;
        padding-left: 15px;
        position: relative;
        width: 190px;
        z-index: 6;
        color: rgba(43, 61, 81, 0.8); }
        #main.enlarged .left.main-sidebar #sidebar-menu ul > li:hover > ul a:hover {
          color: #64b0f2; }
    #main.enlarged .left.main-sidebar #sidebar-menu ul > li:hover a span {
      display: inline; }
  #main.enlarged .left.main-sidebar #sidebar-menu ul > li > ul {
    display: none; }
  #main.enlarged .left.main-sidebar #sidebar-menu ul ul li:hover > ul {
    display: block;
    left: 190px;
    margin-top: -36px;
    position: absolute;
    width: 190px; }
  #main.enlarged .left.main-sidebar #sidebar-menu ul ul li > a span.pull-right {
    -ms-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    position: absolute;
    right: 20px;
    top: 12px;
    transform: rotate(270deg); }
  #main.enlarged .left.main-sidebar #sidebar-menu ul > li > a span {
    display: none;
    padding-left: 10px; }
#main.enlarged .left.main-sidebar #sidebar-menu > ul > li > a {
  padding: 15px 20px;
  min-height: 52.09px; }
#main.enlarged .left.main-sidebar #sidebar-menu > ul > li > a i {
  margin-right: 20px !important;
  font-size: 20px; }
#main.enlarged .left.main-sidebar #sidebar-menu ul > li:hover > a {
  position: relative;
  width: 260px;
  background-color: #535b63;
  color: #ffffff; }
#main.enlarged .left.main-sidebar #sidebar-menu ul > li:hover > a i {
    color: #ffffff; }
#main.enlarged .content-page {
  margin-left: 70px; }
#main.enlarged .navbar-custom {
  margin-left: 70px; }
#main.enlarged .headerbar .headerbar-left {
  width: 70px !important; }
  #main.enlarged .headerbar .headerbar-left .logo span {
    display: none;
    opacity: 0; }
  #main.enlarged .headerbar .headerbar-left .logo i {
    margin-right: 0; }
#main.enlarged #sidebar-menu > ul > li:hover > a.open :after {
  display: none; }
#main.enlarged #sidebar-menu > ul > li:hover > a.active :after {
  display: none; }
#main.enlarged .user-detail {
  bottom: 0;
  padding: 15px 0;
  width: 70px;
  text-align: center; }
  #main.enlarged .user-detail .dropup {
    margin: 0 auto;
    margin-left: 17px; }
  #main.enlarged .user-detail h5 {
    display: none; }
  #main.enlarged .user-detail p {
    position: absolute;
    right: 15px;
    top: 32px; }
    #main.enlarged .user-detail p span {
      display: none; }
#main.enlarged #sidebar-menu ul ul li.active a {
  color: #0f9df7; }



.notif {
	margin-left: 0 !important;
}

.notif .noti-title {
    border-radius: 0;
    background-color: #3492ea;
    margin: 0;
    width: auto;
    padding: 8px 15px 12px 15px;
}

.notif .noti-title h5 {
    color: #ffffff;
    margin: 0;
}

.notif .noti-title .label {
      float: right;
}

.notif .noti-icon {
    font-size: 22px;
    padding: 0 12px;
    vertical-align: middle;
    color: rgba(255, 255, 255, 0.8);
}

.notif .notif-bullet {
    height: 12px;
    width: 12px;
    background-color: #f95200;
    display: inline-block;
    position: absolute;
    top: 13px;
    right: 8px;
    border-radius: 50%;
    border: 2px solid #e2e2e2;
}

.notif .notify-item {
    padding: 10px 20px;
}

.notif .notify-item .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;
      color: #ffffff;
}

.notif .notify-item .notify-icon img {
    margin-top: 4px;
}

.notif .notify-item .notify-details {
    margin-bottom: 0;
    overflow: hidden;
    margin-left: 45px;
    text-overflow: ellipsis;
    white-space: inherit;
}

.notif .notify-item .notify-details b {
	font-weight: 500;
}

.notif .notify-item .notify-details small {
    display: block;
}

.notif .notify-item .notify-details span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
}

.notif .notify-all {
    border-radius: 0;
    margin: 0;
    background-color: #a8c1d8;
	color: #fff;
}

.profile-dropdown {
	width: 170px;
}

.profile-dropdown i {
    font-size: 17px;
    vertical-align: middle;
    margin-right: 5px;
}

.profile-dropdown span {
    vertical-align: middle;
}

.nav-user {
	padding: 0 12px !important;
}

.nav-user img {
    height: 36px;
    width: 36px; 
	margin-bottom: 7px;
}

@media (max-width: 768px) {
	body {
		overflow-x: hidden;
	}

	.enlarged .left.main-sidebar {
		margin-left: -75px;
	}

	.headerbar-left {
		width: 70px !important;
	}
    
	.headerbar-left span {
		display: none !important;
	}
    
	.headerbar-left i {
		display: block !important;
		line-height: 75px !important;
	}

	.navbar-custom {
		margin-left: 70px !important;
	}

	.content-page {
		margin-left: 0 !important;
	}

	.footer {
		left: 0 !important;
	}

	.mobile-sidebar {
		left: 0;
	}

	.mobile-content {
		left: 250px;
		right: -250px;
	}

	.wrapper-page {
		width: 90%;
	}

	.dataTables_wrapper .col-xs-6 {
		width: 100%;
		text-align: left;
	}

	div#datatable-buttons_info {
		float: none;
	}

	.ms-container {
		width: 100%;
	}

	.navbar-toggleable-md .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}
}

@media (max-width: 767px) {
	.dropdown-lg {
		width: 200px;
	}

	.notif .notify-item {
		padding: 10px 15px;
	}

	.breadcrumb-holder .main-title, .breadcrumb-holder .breadcrumb {
		float: none;
	}
}
	
@media (max-width: 480px) {
	.notif .noti-icon {
		font-size: 18px;
		padding: 0 10px;
	}

	.hidden-mobile {
		display: none !important;
	}
}


body.adminbody-void {
  min-height: 1170px; }

.mobile.smallscreen .sidebar-inner.leftscroll {
	overflow: scroll !important;
}

button:focus {
	outline: none !important;
}

a.btn:focus {
	color: #ffffff !important;
}

.btn-rounded {
	border-radius: 2em !important;
	padding: .5rem 1.5rem;
}

.btn-label {
  background: rgba(43, 61, 81, 0.1);
  display: inline-block;
  padding: 7px 15px;
  border-radius: 3px 0 0 3px;
  margin: -7px -14px;
  margin-right: 12px; }

.btn-rounded .btn-label {
  padding: 7px 15px 7px 20px;
  margin-left: -20px; }

.btn-rounded .btn-label-right {
  margin-right: -20px;
  margin-left: 12px; }

.btn-label-right {
  margin-left: 12px;
  margin-right: -13px;
  border-radius: 0px 3px 3px 0px; }

.btn-custom {
  background-color: #64b0f2;
  border-color: #64b0f2;
  color: #ffffff; }

.btn-custom:hover, .btn-custom:focus, .btn-custom:active, .btn-custom.active,
.btn-custom.focus, .btn-custom:active, .btn-custom:focus, .btn-custom:hover,
.open > .dropdown-toggle.btn-custom {
  background-color: #439fef;
  border-color: #439fef;
  color: #ffffff; }

.btn-primary {
  background-color: #0f9df7;
  border-color: #0f9df7; }

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active,
.btn-primary.focus, .btn-primary:active, .btn-primary:focus, .btn-primary:hover,
.open > .dropdown-toggle.btn-primary {
  background-color: #0283d6;
  border-color: #0283d6;
  color: #ffffff; }

.btn-success {
  background-color: #1bb99a;
  border-color: #1bb99a; }

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active,
.btn-success.focus, .btn-success:active, .btn-success:focus, .btn-success:hover,
.open > .dropdown-toggle.btn-success {
  background-color: #16957c;
  border-color: #16957c;
  color: #ffffff; }

.btn-info {
  background-color: #3db9dc;
  border-color: #3db9dc; }

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active,
.btn-info.focus, .btn-info:active, .btn-info:focus, .btn-info:hover,
.open > .dropdown-toggle.btn-info {
  background-color: #25a7cb;
  border-color: #25a7cb;
  color: #ffffff; }

.btn-warning {
  background-color: #f1b53d;
  border-color: #f1b53d;
  color: #ffffff; }

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active,
.btn-warning.focus, .btn-warning:active, .btn-warning:focus, .btn-warning:hover,
.open > .dropdown-toggle.btn-warning {
  background-color: #eea617;
  border-color: #eea617;
  color: #ffffff; }

.btn-danger {
  background-color: #ff5d48;
  border-color: #ff5d48; }

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active,
.btn-danger.focus, .btn-danger:active, .btn-danger:focus, .btn-danger:hover,
.open > .dropdown-toggle.btn-danger {
  background-color: #ff391f;
  border-color: #ff391f;
  color: #ffffff; }

.btn-dark {
  background-color: #2b3d51;
  border-color: #2b3d51;
  color: #ffffff; }

.btn-dark:hover, .btn-dark:focus, .btn-dark:active, .btn-dark.active,
.btn-dark.focus, .btn-dark:active, .btn-dark:focus, .btn-dark:hover,
.open > .dropdown-toggle.btn-dark {
  background-color: #1d2936;
  border-color: #1d2936;
  color: #ffffff; }

.btn-outline-custom {
  color: #64b0f2;
  background-image: none;
  background-color: transparent;
  border-color: #64b0f2; }

.btn-outline-custom:hover, .btn-outline-custom:focus, .btn-outline-custom:active, .btn-outline-custom.active,
.btn-outline-custom.focus, .btn-outline-custom:active, .btn-outline-custom-primary:focus, .btn-outline-custom:hover,
.open > .dropdown-toggle.btn-outline-custom {
  color: #ffffff;
  background-color: #64b0f2;
  border-color: #64b0f2; }

.btn-outline-primary {
  color: #0f9df7;
  background-image: none;
  background-color: transparent;
  border-color: #0f9df7; }

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active,
.btn-outline-primary.focus, .btn-outline-primary:active, .btn-outline-primary:focus, .btn-outline-primary:hover,
.open > .dropdown-toggle.btn-outline-primary {
  color: #ffffff;
  background-color: #0f9df7;
  border-color: #0f9df7; }

.btn-outline-success {
  color: #1bb99a;
  background-image: none;
  background-color: transparent;
  border-color: #1bb99a; }

.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active,
.btn-outline-success.focus, .btn-outline-success:active, .btn-outline-success:focus, .btn-outline-success:hover,
.open > .dropdown-toggle.btn-outline-success {
  color: #ffffff;
  background-color: #1bb99a;
  border-color: #1bb99a; }

.btn-outline-info {
  color: #3db9dc;
  background-image: none;
  background-color: transparent;
  border-color: #3db9dc; }

.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active,
.btn-outline-info.focus, .btn-outline-info:active, .btn-outline-info:focus, .btn-outline-info:hover,
.open > .dropdown-toggle.btn-outline-info {
  color: #ffffff;
  background-color: #3db9dc;
  border-color: #3db9dc; }

.btn-outline-warning {
  color: #f1b53d;
  background-image: none;
  background-color: transparent;
  border-color: #f1b53d; }

.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active, .btn-outline-warning.active,
.btn-outline-warning.focus, .btn-outline-warning:active, .btn-outline-warning:focus, .btn-outline-warning:hover,
.open > .dropdown-toggle.btn-outline-warning {
  color: #ffffff;
  background-color: #f1b53d;
  border-color: #f1b53d; }

.btn-outline-danger {
  color: #ff5d48;
  background-image: none;
  background-color: transparent;
  border-color: #ff5d48; }

.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active, .btn-outline-danger.active,
.btn-outline-danger.focus, .btn-outline-danger:active, .btn-outline-danger:focus, .btn-outline-danger:hover,
.open > .dropdown-toggle.btn-outline-danger {
  color: #ffffff;
  background-color: #ff5d48;
  border-color: #ff5d48; }

.btn-outline-pink {
  color: #ff7aa3;
  background-image: none;
  background-color: transparent;
  border-color: #ff7aa3; }

.btn-outline-pink:hover, .btn-outline-pink:focus, .btn-outline-pink:active, .btn-outline-pink.active,
.btn-outline-pink.focus, .btn-outline-pink:active, .btn-outline-pink:focus, .btn-outline-pink:hover,
.open > .dropdown-toggle.btn-outline-pink {
  color: #ffffff;
  background-color: #ff7aa3;
  border-color: #ff7aa3; }

.btn-outline-purple {
  color: #9261c6;
  background-image: none;
  background-color: transparent;
  border-color: #9261c6; }

.btn-outline-purple:hover, .btn-outline-purple:focus, .btn-outline-purple:active, .btn-outline-purple.active,
.btn-outline-purple.focus, .btn-outline-purple:active, .btn-outline-purple:focus, .btn-outline-purple:hover,
.open > .dropdown-toggle.btn-outline-purple {
	color: #ffffff;
	background-color: #9261c6;
	border-color: #9261c6;
}

.btn-outline-dark {
	color: #2b3d51;
	background-image: none;
	background-color: transparent;
	border-color: #2b3d51;
}

.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active, .btn-outline-dark.active,
.btn-outline-dark.focus, .btn-outline-dark:active, .btn-outline-dark:focus, .btn-outline-dark:hover,
.open > .dropdown-toggle.btn-outline-dark {
	color: #ffffff;
	background-color: #2b3d51;
	border-color: #2b3d51;
}

.btn-facebook {
	color: #ffffff !important;
	background-color: #3b5998 !important;
}

.btn-twitter {
	color: #ffffff !important;
	background-color: #00aced !important;
}

.btn-linkedin {
	color: #ffffff !important;
	background-color: #007bb6 !important;
}

.btn-dribbble {
	color: #ffffff !important;
	background-color: #ea4c89 !important;
}

.btn-googleplus {
	color: #ffffff !important;
	background-color: #dd4b39 !important;
}

.btn-instagram {
	color: #ffffff !important;
	background-color: #517fa4 !important;
}

.btn-pinterest {
	color: #ffffff !important;
	background-color: #cb2027 !important;
}

.btn-dropbox {
	color: #ffffff !important;
	background-color: #007ee5 !important;
}

.btn-flickr {
	color: #ffffff !important;
	background-color: #ff0084 !important;
}

.btn-tumblr {
	color: #ffffff !important;
	background-color: #32506d !important;
}

.btn-skype {
	color: #ffffff !important;
	background-color: #00aff0 !important;
}

.btn-youtube {
	color: #ffffff !important;
	background-color: #bb0000 !important;
}

.btn-github {
	color: #ffffff !important;
	background-color: #171515 !important;
}



.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

.display-block {
	display: block;
}

.dropdown-example {
	position: static;
	display: block;
	margin-bottom: .25rem;
	clear: left;
}

.dropdown-item.active, .dropdown-item:active {
	background-color: #0f9df7;
}

#sidebar-menu > ul > li > a.pro {    
	background-color: #f16854;
	color: #fff;
	bottom: 0;
}	

.avatar-circle {
    border-radius: 50%;
}

.avatar-rounded {
    border-radius: 50%;
}

.widget-messages .message-item {
    position: relative;
	overflow: hidden;
	border-bottom: 1px solid #e8e8e8;    
    padding: 12px 0;  
}

.widget-messages .message-item img {
    width: 40px;
}

.widget-messages .message-item .message-user-img {
    display: block;
    float: left;
    margin-right: 15px;
    width: 40px;
}

.widget-messages .message-item .message-item-user {
	color: #525252;
    display: block;
	font-weight: 500;
    margin: 0;
}

.widget-messages .message-item .message-item-msg {
	color: #525252;
    display: block;
	font-weight: 200;
    margin: 0;
}

.widget-messages .message-item .message-item-date {
	color: #a2a5a7;
	font-weight: 200;
    position: absolute;
    right: 7px;
    top: 2px;
}


.custom-modal .modal-header {    
    padding: 10px !important;
    border-radius: 0;
    background-color: #5ba2e6;
	color: #fff;
}

.custom-modal .modal-content {    
    background-color: #f7f7f7;
}

.custom-modal .modal-footer {    
    padding: 10px !important;
    border-radius: 0;
    background-color: #f3f3f3;
}

.custom-modal .close {
    color: #fff;
    text-shadow: 0;
}

.border-left {
	border-left: 3px solid #eaeaea;
}

.label-info {
    background-color: #5bc0de;
}

.align-items-center {
  -ms-flex-align: center!important;
  align-items: center!important;
}
.d-flex {
  display: -ms-flexbox!important;
  display: flex!important;
}

.check-lable {
	padding:3px;
}

.child-margin {
	margin:0px;
}

.patient-bg {
	background-color: #14A39F;
	color: #fff;
	font-weight: 100;
	padding: 10px; 
}

.bg-body {
	background-color: #fff;
	padding: 10px;
}

.text-head {
	font-size: 20px;
	padding: 5px;
}

.footer-text {
	color: #fff;
	font-weight: 400;
	text-align: center;
	
}

.bottom-sidebar{
   position:absolute;
   bottom:0;
   width:100%;
   
}

.dob {
	width: 50%;
}
.f1{
	float: left;
}

.f2 {
	float:right;
}
@media (max-width: 480px) {
	.radio-mobile{
		margin-left:8.333333%;
	}
	.dob{
		width: 100%;
	}
	.footer-m {
		text-align: center;	
		float: none !important;
	}
}
/*---grid css start ----*/
.grid-body {
	background-color: #FFF;
	padding: 20px;
}

.control-body {
	background-color: #ECEAEB;
}

.table-spacing {
	margin-top:12px;
}

.grid-header {
	background-color: #98A8B5;
	border-bottom: 4px solid #fff;
}

.grid-cell-1 {
	background-color: #E7EBEC;
}

.grid-cell-2 {
	background-color: #FFFFFF;
}

.in-ic {
    background-color: #FFF;
	border-top-right-radius: .40rem !important;
	border-bottom-right-radius: .40rem !important;
	border-left:none;
}

.field-text {
	font-size:13px;
	font-weight: bold;
}

.in-search {
    background-color: #4FB09D;
	
	margin-left: 4px;
} 

.icon-space {
	margin-left: 5px;
}
.a-search {
	color: #fff;
}

.a-search:hover {
	color: #fff;
}

.a-display {
	color: #31A797;
	font-size: 18px;
}

.a-display:hover {
	color: #31A797;
}

.a-edit {
	color: #3D6FAE;
	font-size: 18px;
}

.a-edit:hover {
	color: #3D6FAE;
}

.a-trash {
	color: #D26465;
	font-size: 18px;
}

.a-trash:hover {
	color: #D26465;
}

.form-custom {
	background-color: #FFF;
	border-top-left-radius: .40rem !important;
	border-bottom-left-radius: .40rem !important;
	border-right:none;
	
}

.form-border-all {
	border-radius: .40rem !important;
}

.form-inline-spacing {
	padding:5px;
}

/*---grid css end ----*/




/*---Modal css Start ----*/
.modal-dialog-width {
	max-width: 460px;
}

.modal-content-custom {
	border: none;
	border-radius: 0x;
}

.modal-custom-header {
	background-color: #3ea18d;
	color: #FFF;
	padding: 0.5rem 0.5rem;
}

.modal-text {
	
	line-height: 2;
}

.modal-text-main {
	color: #E67624;
	font-weight: 600;
	font-size: 18px;
}

.content-spacing {
	padding-top: 10px;
}

.v-center {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 420px) and (min-width: 320px) {
	.custom-center {
 		text-align: center;
		
	}
}

/*---Modal css end ----*/

/*---accordion form css end ----*/
.pd-body {
	background-color: #fff;
	padding: 15px;
}


.pd-card-space {
	margin-top: 5px;
	border: 1px solid rgba(0,0,0,.125) !important;
	border-radius: .25rem !important;
}

.pd-head-text {
	padding:10px;
	color: #E77423;
}

.pd-info-card {
	background-color: #E8F3F5;
	padding:15px;
	border-radius: 5px;
}

.pd-collapse-head {
	background-color: #D0DCE8;
	
	padding: 0px;
}

.pd-card-text {
	color: #133656;
}

.pd-card-text:hover {
	text-decoration:none;
	color: #133656;
}

.pd-chip {
  display: inline-block;

padding: 0px 7px;

height: 26px;

font-size: 18px;

line-height: 23px;

border-radius: 4px;

background-color: #F1CF6B;
margin-top: 8px;
  
}

.pd-chip-one {
  display: inline-block;

padding: 0px 7px;

height: 26px;

font-size: 18px;

line-height: 23px;

border-radius: 4px;


	
  background-color: #FD9A57;
	margin-top: 8px;
  
}

.pd-closebtn {
  padding-left: 10px;
  color: #888;
  font-weight: bold;
  float: right;
  font-size: 15px;
  cursor: pointer;
}

.pd-closebtn:hover {
  color: #000;
}

.pd-chip-text {
	font-size: 11px;
}

.pd-btn-add {
	background-color: #4EAC9A;
	border-color: #4EAC9A;
}

.pd-btn-add:hover {
	background-color: #4EAC9A;
	border-color: #4EAC9A;
}

.pd-button-save {
	background-color: #7992A8;
	border-color: #7992A8;
}

.pd-button-save:hover {
	background-color: #7992A8;
	border-color: #7992A8;
}

.patient-title-detail {
	margin-top:15px;
}

.pd-custom-check {
	
  width: 1.3em;
    height: 1.3em;
    background-color: #FFF;
    border-radius:5px;
    vertical-align: middle;
    border: 1px solid #c3c3c3;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}


.pd-custom-check:checked {
    background-color: #DDD;
	
}

.pd-diagnosis-text {
	font-size: 17px;
	font-weight: 500;
}

.pd-fa-space {
	padding: 0px 7px;
}

.card-header.active {
  background-color: #ffd051;
}

@media only screen and (max-width: 767px) and (min-width: 300px) {
	.pd-card-margin {
		margin-top: 6px;
	}
	
	.pd-head-text {
		padding:10px;
		color: #E77423;
		text-align: center;
}
	
}
/*---accordion form css end ----*/

/*---patient preview css start ----*/
.pt-preview-head {
	background-color:#E9EEF1;
	padding: 3px 10px;
	
	
}

.pt-preview-head-text {
	color:#133656 !important;
	font-size: 16px;
	
}

.pt-preview-desc-text {
	line-height: 0.9;
	padding: 15px;
}

.pt-preview-btn {
	padding: 4px 25px;
}

@media only screen and (max-width: 580px) and (min-width: 300px) {
	.pt-btn-align {
		display: flex !important;
		justify-content: center !important;
	}
	
	
}



pd-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}





.pd-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}





.pd-checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #eee;
  border: 1px solid #c3c3c3;
  border-radius: 4px;
}





.pd-container:hover input ~ .pd-checkmark {
  background-color: #ccc;
}





.pd-container input:checked ~ .pd-checkmark {
  background-color: #2196F3;
}





.pd-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}





.pd-container input:checked ~ .pd-checkmark:after {
  display: block;
}





.pd-container .pd-checkmark:after {
  left: 5px;
  top: 0px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.md_rcrd_cls_btn {
  position: absolute;
  background: #E77423;
  color: white;
  top: -10px;
  right: 40%;
}
	
/*---patient preview css end ----*/




/*tooltip css start ----*/

.newtooltip {
  font-size: 12px !important;
  padding: 8px 8px 5px 5px !important;
  background: #14A39F !important;
  color: rgb(0, 0, 0, .7) !important;
  text-align: left !important;
  color: #fff !important;
  line-height: 2px !important;
  font-size: 14px !important;
  border-bottom-color: #14A39F !important;
 
}


.registration_form {
  margin-top: 12px;
}


.pt-back-btn {
  background-color: #7992A8;
  border-color: #7992A8;
}

  .pt-back-btn:hover {
    background-color: #7992A8;
    border-color: #7992A8;
  }

  .pt-back-btn:focus {
    background-color: #7992A8;
    border-color: #7992A8;
  }

.pt-table {
  padding: 15px;
}


.odd {
  display: inline-block;
  padding: 2px 7px;
  height: 26px;
  font-size: 18px;
  line-height: 23px;
  border-radius: 4px;
  background-color: #FD9A57;
  margin-top: 8px;
  margin-left: 2px;
}


.even {
  display: inline-block;
  padding: 2px 7px;
  height: 26px;
  font-size: 18px;
  line-height: 23px;
  border-radius: 4px;
  background-color: #F1CF6B;
  margin-top: 8px;
  margin-left: 2px;
}



.center-info-text {
  padding-top: 18px;
  color: #133656;
}



/*----- prescription css start ----- */
.pres-body {
  background: #fff;
}

.pres-main {
  margin: 20px;
  border-left: 5px solid #0094E0;
  /*height: 600px;*/
  padding: 10px;
}

.pres-text {
  margin-bottom: 1px;
}
@media print {
  tr:nth-child(even) td {
    background-color:#DADADA !important;
    -webkit-print-color-adjust:exact;
  }
}
.pres-pgi {
  color: #0A426B;
  font-weight: 600;
  font-size: 13px;
}

.pres-pgi-detail {
  font-size: 13px;
}

.pres-pt-detail {
  border: 1px solid #c3c3c3;
}

.pres-pt-info {
  font-weight: 600;
  color: #10204D;
}

.pres-pt-body {
  background-color: #EAEAEA;
}

.grid-header-1 {
  background-color: #D5E3EA;
}

.pres-add-info {
  font-weight: 600;
  color: #10204D;
}

.pres-color {
  color: #10204D;
}

hr.pres-hr {
  border-top: 3px solid #97BB6F;
}

.pres-pgi-text {
  text-align: right;
}

.pres-pt-stat {
  text-align: right;
}
.pres-offset{
  margin-left:6.5555%;
}

.pres-spacing {
  margin-left:0px !important;
  margin-right:0px !important;
}

@media only screen and (max-width: 580px) and (min-width: 300px) {
  .pres-pgi-text {
    text-align: center;
  }

  .pres-pt-stat {
    text-align: left;
  }
}
/*----- prescription css end ------ */





@media only screen
and (min-device-width : 575px)
and (max-device-width : 1050px) {
  .add-pt-custom-width {
    max-width:100%;
    flex:0 0 100%;
    margin-top: 1.2rem !important;
  }
}
.form-custom-spacing {
  margin-bottom: 0rem !important;
}


@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
  .list-pt-width {
    margin-top: 8px;
  }
}

@media only screen and (min-device-width : 1200px) and (max-device-width : 1548px) {
  .report-field-width {
    width: 70%;
  }
}



@media only screen and (max-device-width: 575px) {
  .case-custom-spacing {
    margin-top: 1.2rem;
  }
}


.admin_header{
  background-color: #ffffff;
    margin: 0px 0px 20px 0px;
    padding: 14px 0px 14px 0px;
    box-shadow: none;
}
.national_heading{
  text-align: center; float: inherit; font-size: 22px; 
}
.admin_icon{
  float: right;
}
.dashboardadmin_dropdown{
  text-align: right; float: right;
}
.admin_dropdown_inner{
  height: 10px; 
}
.dropdown_button{
  color: #fff; background-color: #133656;
    border-color: #133656;
}
.dropdown_button:hover{
  color: #fff; background-color: #133656;
    border-color: #133656;
}
.drop_down_list{
  overflow-x: hidden; overflow-y: scroll; height: 300px;
}

.sendrec{
position: absolute; bottom: 49%; left: 76%;
}
.vctime{
  position: absolute; bottom: 3%; left: 34%;
}
.consulttime{
  position: absolute; bottom: 3%; left: 34%;
}
.radiobox_dashboards{
  padding: 0px 10px 0px 5px;
}

@media only screen and (min-device-width : 1025px) and (max-device-width : 1199px) {
	.boxes { padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}
}

@media only screen and (min-device-width : 1200px) and (max-device-width : 1458px) {
	.boxes-height {
		height: 102.5px;
	}
	
	.dashboard_link {
		display: none;
	}
	
	 .dashboard_link_2 {
		padding: 1px 1px 5px 7px;
    
    font-family: roboto;
    font-size: 14px;
    color: #ffffff;
	}
	
	.dashboard_link_1 {
		padding: 1px 1px 0px 7px;
    
    font-family: roboto;
    font-size: 14px;
    color: #ffffff;
		
	}
	
}

@media only screen and (min-device-width : 1px) and (max-device-width : 1199px) {
	.dashboard_link_1 {
		display: none;
	}
	.dashboard_link_2 {
		display: none;
	}
}

@media only screen and (min-device-width : 1459px) {
	.dashboard_link_1 {
		display: none;
	}
	.dashboard_link_2 {
		display: none;
	}
	
}